@use "ui-kit-old" as *;
@use "ui-kit" as *;

@tailwind components;
@tailwind base;
@tailwind utilities;

* {
  box-sizing: border-box;
}

textarea:focus,
input:focus {
  outline: none;
}

body,
html,
#root {
  margin: 0;
  font-size: 16px;
  font-weight: $normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $lightGray;

  width: 100%;
  height: 100%;
  --sweet-safe-left: 0px;
  --sweet-safe-right: 0px;
  --sweet-safe-bottom: 0px;
  --sweet-safe-top: 0px;
  overflow: hidden;

  // Firefox
  scrollbar-color: $iconGray transparent;

  // Other browsers
  ::-webkit-scrollbar {
    width: 7px; /* Mostly for vertical scrollbars */
    height: 7px; /* Mostly for horizontal scrollbars */
  }
  ::-webkit-scrollbar-thumb {
    /* Foreground */
    background: $iconGray;
    border-radius: 3px;
    height: auto;
    min-height: 50px;
    max-height: 50%;
    border: 10px solid transparent;
  }
  ::-webkit-scrollbar-track {
    /* Background */
    background: transparent;
  }
}
